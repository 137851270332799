<script>
  import { onMount } from "svelte";
  import { building, dev } from "$app/environment";
  import { invalidateAll } from "$app/navigation";
  import { page } from "$app/stores";
  import { supabase } from "$lib/services/db";
  import { project } from "$lib/services/store";
  import {
    organization,
    article,
    ogData,
    generalHeadTags,
  } from "$lib/data/mappers/structured-data";
  import "../../../app.postcss";
  import Announcement from "$lib/main/components/Announcement.svelte";
  import Nav from "$lib/main/Nav.svelte";
  import Sidebar from "$lib/main/Sidebar.svelte";
  import Footer from '$lib/main/Footer.svelte'
  import MobileNav from '$lib/main/MobileNav.svelte'
  import GoToTopButton from '$lib/main/GoToTopButton.svelte'
  import GoogleTagManager from '$lib/main/GoogleTagManager.svelte'
  import { initializeCookieYesEventListener, attachTriggerLink } from "$lib/services/cookieYes";

  onMount(() => {
    initializeCookieYesEventListener();
    attachTriggerLink();
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(() => {
      invalidateAll();
    });
    return () => {
      subscription.unsubscribe();
    };
  });

  let noIndex;
  noIndex = import.meta.env.VITE_NO_INDEX === "true";

  export let data;
  const { centralData } = data;

  project.set(data.project);
</script>

<svelte:head>
  {#if noIndex}
    <meta name="robots" content="noindex, noarchive" />
  {:else}
    <meta name="robots" content="index, follow" />
  {/if}

  {@html organization($page.data.project)}
  {@html article($page.data.post, $page.data.usersData, $page.data.project)}
  {@html ogData(
    $page.data.post,
    $page.data.project,
    $page.data.project.settings.url,
    $page.url.pathname
  )}
  {@html generalHeadTags(
    $page.data.post,
    $page.data.usersData,
    $page.data.project,
    building,
    $page.data.project.settings.url,
    $page.url.pathname
  )}
  <!-- Start VWO Async SmartCode -->
  <link rel="preconnect" href="
  https://dev.visualwebsiteoptimizer.com"
  />
  <script type='text/javascript' data-cfasync="false" id='vwoCode'>
  window._vwo_code || (function() {
  var account_id=788215,
  version=2.0,
  settings_tolerance=2000,
  hide_element='body',
  hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
  f=false,w=window,d=document,v=d.querySelector('#vwoCode'),cK='_vwo_'+account_id+'_settings',cc={};try{var c=JSON.parse(localStorage.getItem('_vwo_'+account_id+'_config'));cc=c&&typeof c==='object'?c:{}}catch(e){}var stT=cc.stT==='session'?w.sessionStorage:w.localStorage;code={use_existing_jquery:function(){return typeof use_existing_jquery!=='undefined'?use_existing_jquery:undefined},library_tolerance:function(){return typeof library_tolerance!=='undefined'?library_tolerance:undefined},settings_tolerance:function(){return cc.sT||settings_tolerance},hide_element_style:function(){return'{'+(cc.hES||hide_element_style)+'}'},hide_element:function(){return typeof cc.hE==='string'?cc.hE:hide_element},getVersion:function(){return version},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=this.getSettings(),n=d.createElement('script'),i=this;if(t){n.textContent=t;d.getElementsByTagName('head')[0].appendChild(n);if(!w.VWO||VWO.caE){stT.removeItem(cK);i.load(e)}}else{n.fetchPriority='high';n.src=e;n.type='text/javascript';n.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(n)}},getSettings:function(){try{var e=stT.getItem(cK);if(!e){return}e=JSON.parse(e);if(Date.now()>e.e){stT.removeItem(cK);return}return e.s}catch(e){return}},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;var e=this.settings_tolerance();w._vwo_settings_timer=setTimeout(function(){_vwo_code.finish();stT.removeItem(cK)},e);var t=d.currentScript,n=d.createElement('style'),i=this.hide_element(),r=t&&!t.async&&i?i+this.hide_element_style():'',c=d.getElementsByTagName('head')[0];n.setAttribute('id','_vis_opt_path_hides');v&&n.setAttribute('nonce',v.nonce);n.setAttribute('type','text/css');if(n.styleSheet)n.styleSheet.cssText=r;else n.appendChild(d.createTextNode(r));c.appendChild(n);this.load('
  https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&vn='+version)}};w._vwo_code=code;code.init();})();
  </script>
  <!-- End VWO Async SmartCode -->
</svelte:head>

{#if building || !dev}
  <GoogleTagManager />
{/if}

<div
  class={"body t-" + $project.settings.theme + " p-" + $project.key}
  data-sveltekit-reload
>
  {#if $project.settings.announcement?.status == "active"}
    <Announcement
      type={$project.settings.announcement?.type}
      html={$project.settings.announcement?.html}
    />
  {/if}

  <Nav />

  <main class:sidebar={$project.settings?.sidebar}>
    <slot />

    {#if $project.settings?.sidebar}
      <Sidebar />
    {/if}
  </main>
  <!-- TODO:
    what is best: to pass the type from layout (like this) or to pass it directly in the footer component?
    it feels cleaner to move it into the component, but then it will be rendered on client-side? Unless we move that to +page.server.js (if we can?)
    Note that with current setup moving the type setting under Footer component makes the component render the front-end footer in the back-end (should be a simple way to fix though)
  -->
  <Footer address={centralData.data.address} />
</div>

{#if $project.menu.mobileMenu}
  <MobileNav />
{/if}

<GoToTopButton />
