<script>
  import { mobileNavHeight } from "$lib/services/store";

  let yScroll;

  $: bottomOffset = `calc(1rem + ${$mobileNavHeight}px);`

  function goToTop() {
    window.scrollTo(0, 0)
  }
</script>

<svelte:window bind:scrollY={yScroll} />

{#if yScroll >= 1000}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <span
    style={`bottom: ${bottomOffset}`}
    class="go-to-top"
    on:click={goToTop}></span>
{/if}