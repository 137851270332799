<script>
  import { onMount } from "svelte";
  import { project } from "$lib/services/store";

  onMount(() => {
    const GTM_ID = $project.data.tags.gtm;

    if (typeof window !== "undefined") {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        j.addEventListener("load", function () {
          console.log("GTM Loaded");
        });
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", GTM_ID);
    }
  });
</script>

<!-- Google Tag Manager (noscript) -->
<noscript>
  <!-- svelte-ignore a11y-missing-attribute -->
  <iframe
    src={`https://www.googletagmanager.com/ns.html?id=${$project.data.tags.gtm}`}
    height="0"
    width="0"
    style="display:none;visibility:hidden"
  />
</noscript>
<!-- End Google Tag Manager (noscript) -->
